import store from "@/store";
import router from '@/router'
import axios from "axios";
import { Notify } from 'vant';
const api = axios.create({
  baseURL: '/api',
  timeout: 2000 * 10
})
//错误消息提示
const TipMessage = msg => {
  Notify(msg);
}
// post请求头
api.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8"

//请求拦截处理
api.interceptors.request.use(config => {
  // const token = decodeURI(localStorage.getItem('TOKEN'))
  const token = localStorage.getItem('TOKEN')
  // const token = 'Bearer%201176%7CEnSP9IaXkrRZSm76n5p3dm8PaMjh9qNHzEEMbM8Z'
  // store.state.token;
  token && (config.headers.Authorization = token)
  return config
}, err => {
  Promise.reject(err)
})
// 状态码封装
const errorHandle = (status) => {
  switch (status) {
    case 401:
      TipMessage("登录过期，请重新登录");
      // 删除token
      localStorage.removeItem("TOKEN")
      router.push('/login')
      break;
    case 403:
      break
    case 404:
      TipMessage("请求的资源不存在");
      break
  }
}
// 响应拦截处理
api.interceptors.response.use(
  res => (res.status == 200 ? Promise.resolve(res.data) : Promise.reject(res))
  , err => {
    const { response } = err;
    if (response) {
      errorHandle(response.status)
      return Promise.reject(response)
    }
  });

export default api
