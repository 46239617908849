import api from "@/http/api";
//收货地址添加编辑
export const addressApi = (params) => api.post('user/address_add', params)
//获取收货地址列表 详情
export const detailsressApi = (params) => api.post('user/address_info', params)
//删除地址
export const delApi = (params) => api.post(`user/address_del/${params}`)
// 
export const userBeyApi = (params) => api.post('user/userRegisterByCard', params)

export const userApi = (params) => api.post('center/getUserMsg', params)

export const userInfoApi = (params) => api.post('center/getUserMsg', params)
//更新用户信息
export const userupdataApi = (params) => api.post('user/updateUserMsg', params)

export const uploadApi = (params) => api.post('upload', params)

export const newsApi = (params) => api.get('home/getNoticeList', {
  params
})
//获取用户服务记录
export const userOrderApi = (params) => api.post('reservation/getReservationMemberLog', params)

export const evaluationApi = (params) => api.post('reservation/reviewsReservation', params)
export const appraiseApi = (params) => api.post('reservation/getReservationReviewList', params)

// 获取公众号配置
export const getGZHpeizhi = (params) => api.post('official/jsapisign', params)

// 小区查询
export const queryCommunity = (params) => api.post('user/getAddressArea', params)