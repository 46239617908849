import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { userBeyApi } from '../Api/userApi/address.api'
import { queryURLParams } from '../utils/auth'
import { Toast } from 'vant'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'home',
  component: HomeView
},
{
  path: '/order-detail',
  name: 'orderdetail',
  component: () =>
    import("../views/order-detail.vue")
},
{
  path: '/order-wait',
  name: 'orderwait',
  component: () =>
    import("../views/order-wait.vue")
},
{
  path: '/order-pay',
  name: 'orderpay',
  component: () =>
    import("../views/order-pay.vue")
},
{
  path: '/order-service',
  name: 'orderservice',
  component: () =>
    import("../views/order-service.vue")
},
{
  path: '/order-completion',
  name: 'ordercompletion',
  component: () =>
    import("../views/order-completion.vue")
},
{
  path: '/integer-detail',
  name: 'integerdetail',
  component: () =>
    import("../views/integer-detail.vue")
},
{
  path: '/my',
  name: 'my',
  component: () =>
    import("../views/my.vue")
},
{
  path: '/order-list',
  name: 'orderlist',
  component: () =>
    import("../views/order-list.vue")
},
{
  path: '/order-confirmation',
  name: 'orderconfirmation',
  component: () =>
    import("../views/order-confirmation.vue")
},
{
  path: '/service',
  name: 'service',
  component: () =>
    import("../views/service.vue")
},
{
  path: '/service-detail',
  name: 'servicedetail',
  component: () =>
    import("../views/service-detail.vue")
},
{
  path: '/appraise-list',
  name: 'appraiselist',
  component: () =>
    import("../views/appraise-list.vue")
},
{
  path: '/service-record',
  name: 'servicerecord',
  component: () =>
    import("../views/service-record.vue")
},
{
  path: '/service-search',
  name: 'servicesearch',
  component: () =>
    import("../views/service-search.vue")
},
{
  path: '/address-list',
  name: 'addresslist',
  component: () =>
    import("../views/address-list.vue")
},
{
  path: '/address-detail',
  name: 'addressdetail',
  component: () =>
    import("../views/address-detail.vue")
},
{
  path: '/mall',
  name: 'mall',
  component: () =>
    import("../views/mall.vue")
},
{
  path: '/mall-detail',
  name: 'malldetail',
  component: () =>
    import("../views/mall-detail.vue")
},

{
  path: '/mall-classify',
  name: 'mallclassify',
  component: () =>
    import("../views/mall-classify.vue")
},

{
  path: '/information',
  name: 'information',
  component: () =>
    import("../views/information.vue")
},
{
  path: '/login',
  name: 'login',
  component: () =>
    import("../views/login.vue")
},

{
  path: '/appraise-detail',
  name: 'appraisedetail',
  component: () =>
    import("../views/appraise-detail.vue")
},
{
  path: '/vip',
  name: 'vip',
  component: () =>
    import("../views/vip.vue")
},
{
  path: '/questionnaire',
  name: 'questionnaire',
  component: () =>
    import("../views/questionnaire.vue")
},
{
  path: '/empty',
  name: 'empty',
  component: () =>
    import("../views/empty.vue")
},

{
  path: '/sign-in',
  name: 'signin',
  component: () =>
    import("../views/sign-in.vue")
},
{
  path: '/news',
  name: 'news',
  component: () =>
    import("../views/news.vue")
},
{
  path: '/news-detail',
  name: 'newsdetail',
  component: () =>
    import("../views/news-detail.vue")
},

{
  path: '/note',
  name: 'note',
  component: () =>
    import("../views/note.vue")
},

{
  path: '/order-download',
  name: 'order-download',
  component: () =>
    import("../views/order-download.vue")
},


{
  path: '/about',
  name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function () {
    return import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
}
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// router.beforeEach((to, from, next) => {
// const url =  window.location.href
// console.log(url,url.indexOf('questionnaire'));
// Toast(url)
// let dataquer = queryURLParams(url)
// if(url.indexOf('questionnaire') >= 0 && dataquer.id){
//     console.log(url.indexOf('questionnaire'));
//     next('/questionnaire')
// }else{
// }
// })

export default router