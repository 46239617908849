import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { setToken, getToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    specId: '', // 订单规格id
    goodsId: '', // 订单商品id
    remark: '', // 下单备注
    addressId: '', // 地址id
    date: '', // 上门日期
    timeIntervalId: '', // 预约时间段id
    data: {},
    orderDetailData: {}, // 订单列表转详情页所需数据
  },
  getters: {
    getTokenFun (state) {
      return state.token = getToken()
    }
  },
  mutations: {
    goodsIdFun (state, goodsId,) {
      state.goodsId = goodsId
    },
    specIdFun (state, specId) {
      state.specId = specId
    },
    dataFun (state, data) {
      state.data = data
    },
    setPayInfo (state, data) {
      state.specId = data.specId
      state.goodsId = data.goodsId
      state.remark = data.remark
      state.addressId = data.addressId
      state.date = data.date
      state.timeIntervalId = data.timeIntervalId
    },
    setOrderDetailData (state, data) {
      state.orderDetailData = data
    }
  },
  actions: {
    login: function (name, token) {
      setToken(token)
      router.push({
        path: '/'
      })
    }
  },
  modules: {
  }
})
