import Vue from 'vue'
import Vant from 'vant';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

Vue.prototype.$axios = axios

import 'vant/lib/index.css';
import './assets/main.css'

Vue.config.productionTip = false

Vue.use(Vant);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')