<template>
  <router-view />
</template>
<script>
import { queryURLParams } from "./utils/auth";
import { userBeyApi } from "./Api/userApi/address.api";
import { Toast } from "vant";
export default {
  data() {
    return {};
  },
  methods: {
    tokenFun() {
      let url = location.href;
      let dataquer = queryURLParams(url);
      console.log(dataquer);
      if (dataquer.token) {
        dataquer.token = decodeURI(dataquer.token);
        localStorage.setItem("TOKEN", dataquer.token);
      }
      // window.localStorage.setItem('TOKEN', dataquer.token)
      if (dataquer.openid) {
        console.log("openid");
        userBeyApi(dataquer)
          .then(res => {
            localStorage.setItem("TOKEN", res.data.token);
            if (!res.data.token) {
              window.location.href = "/api/official/login";
            }
            this.$router.push("/");
          })
          .catch(err => {});
      } else {
        let token = localStorage.getItem("TOKEN");
        if (token) {
          if (url.indexOf("questionnaire") >= 0) {
            this.$router.push({
              path: "/questionnaire",
              query: dataquer
            });
          } else {
            // console.log(this.$router.currentRoute.fullPath === '/')
            this.$router.push("/");
          }
          return;
        }
        if (dataquer.token) {
          console.log(token);
          localStorage.setItem("TOKEN", dataquer.token);
          this.$router.push("/");
          return;
        }
        if (token == null) {
          // console.log(token)
          window.location.href = "/api/official/login";
        } else {
          this.$router.push("/");
        }
      }
      // next()
    },
    URLencode(sStr) {
      return sStr
        .replace(/\+/g, "%2B")
        .replace(/\"/g, "%22")
        .replace(/\'/g, "%27")
        .replace(/\//g, "%2F");
    }
  },
  created() {
    this.tokenFun();
    // localStorage.setItem(
    //   "TOKEN",
    //   "Bearer 1140|KhzDVz5Un9Nuk3SvjFmtr6TB04aLrGPua0QzQwrf"
    // );
  }
};
</script>
<style lang="scss">
html,
body,
dl,
dt,
dd,
ol,
ul,
li,
p,
span,
em,
cite,
del,
a,
img,
h1,
h2,
h3,
h4,
h5,
h6,
object,
pre,
code,
form,
fieldset,
legend,
pre,
input,
select,
textarea,
blockquote,
q,
table,
caption,
thead,
tbody,
tfoot,
th,
td,
button {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: transparent;
}

html,
body {
  width: 100%;
  word-wrap: break-word;
}

body {
  font-size: 13px;
  color: #383838;
  background: #fff;
  min-height: 40vh;
}

html,
body {
  font-family: "微软雅黑";
}

.body-box {
  background: #fff;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img,
fieldset,
abbr,
acronym {
  border: none;
  vertical-align: top;
}

a img {
  border: none;
  padding: 0;
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

p {
  -webkit-text-size-adjust: none;
}

a {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
}

* {
  margin: 0 auto;
  padding: 0px;
}

/* ----End Reset---- */

input,
textarea,
select,
option,
button {
  border: none;
  background: none;
  outline: none;
  font-family: "siyuan";
}

textarea {
  resize: none;
}

select::-ms-expand {
  display: none;
}

video {
  border: none;
}

/*---- Patchs---- */

.fl {
  float: left;
}

.fr {
  float: right;
}

.fn {
  float: none;
}

.cl {
  clear: left;
}

.cr {
  clear: right;
}

.cb {
  clear: both;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

li {
  list-style: none;
  vertical-align: bottom;
}
</style>
