<template>
  <div>
    <div class="index-topbox">
      <div class="logo">
        <img src="../static/images/logo.png" />
      </div>
      <div class="search-box">
        <div class="search-1">
          <div class="search-11">
            <input type="text" placeholder="输入关键字搜索" v-model="valueData" />
          </div>
          <div class="search-12" @click="sousuoFun">
            <button>搜索</button>
          </div>
        </div>
        <div class="search-2" @click="questionnaire">
          <img src="../static/images/investigation.png" />
        </div>
      </div>
    </div>

    <div class="banner-box">
      <div class="banner">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in imagesList" :key="index">
            <img :src="item.image" @click="bannerClick(item)" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <div class="notify-box">
      <div class="notify-1" @click="news">
        <div class="notify-11">
          <img src="../static/images/notify.png" />
        </div>
        <div class="notify-12">
          <van-notice-bar left-icon="volume-o" :scrollable="false">
            <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
              <van-swipe-item v-for="item in noticeList" :key="item.id" class="underline">
                {{ item.name || ""
                }}
              </van-swipe-item>
            </van-swipe>
          </van-notice-bar>
        </div>
      </div>

      <div class="nav-box">
        <div class="nav-1" @click="service(0)">
          <div class="nav-11">
            <img src="../static/images/icon-1.png" />
          </div>
          <div class="nav-12">预约上门</div>
        </div>
        <div class="nav-1" @click="navigationFun">
          <div class="nav-11">
            <img src="../static/images/icon-2.png" />
          </div>
          <div class="nav-12">来院导航</div>
        </div>
        <div class="nav-1" @click="mall">
          <div class="nav-11">
            <img src="../static/images/icon-3.png" />
          </div>
          <div class="nav-12">积分商城</div>
        </div>
        <div class="nav-1" @click="vip">
          <div class="nav-11">
            <img src="../static/images/icon-4.png" />
          </div>
          <div class="nav-12">会员卡</div>
        </div>
      </div>
    </div>

    <div class="biaoti-box">
      <div class="biaoti-1">服务类型</div>
    </div>

    <div class="leixing-box">
      <div class="leixing-1" @click="service(5)">
        <div class="leixing-11">{{ reservationCategory[0]?.name }}</div>
        <div class="leixing-12">{{ reservationCategory[0]?.desc }}</div>
        <div class="leixing-13">查看详情</div>
      </div>
      <div class="leixing-1" @click="service(4)">
        <div class="leixing-11">{{ reservationCategory[1]?.name }}</div>
        <div class="leixing-12">{{ reservationCategory[1]?.desc }}</div>
      </div>
      <div class="leixing-1" @click="service(2)">
        <div class="leixing-11">{{ reservationCategory[2]?.name }}</div>
        <div class="leixing-12">{{ reservationCategory[2]?.desc }}</div>
      </div>
    </div>

    <div class="xuzhi-box" @click="note">
      <div class="xuzhi-1">上门服务须知</div>
      <div class="xuzhi-2">
        最优质放心的医护服务
        <br />24小时急速接诊
      </div>
      <div class="xuzhi-3">
        查看用户须知
        <img src="../static/images/xuzhi-tb.png" />
      </div>
    </div>

    <div class="biaoti-box biaoti-boxaaa">
      <div class="biaoti-1">积分商城</div>
      <div class="biaoti-2" @click="mall">
        更多
        <img src="../static/images/more.png" />
      </div>
    </div>
    <!-- 积分商城部分修改 -->
    <div class="fuwu-box">
      <div
        class="fuwu-1"
        v-for="item in serveGoodsList"
        :key="item.id"
        @click="servicedetail(item.id)"
      >
        <div class="fuwu-11">
          <img :src="item.image" />
        </div>
        <div class="mall-lb12" style="height: 16px;">{{ item.name_ch }}</div>
        <div class="mall-lb13">
          <img src="../static/images/mall-jf.png" />
          {{ item.score }}
        </div>
      </div>
    </div>

    <div class="pingjia-max">
      <div style="padding: 10px">
        <div class="biaoti-1">热门评价</div>
        <div class="biaoti-2" @click="appraiseList">
          更多
          <img src="../static/images/more.png" />
        </div>
      </div>
      <div class="pingjia-box">
        <div class="pingjia-1" v-for="item in evaluation" :key="item.id">
          <div class="pingjia-11">
            <div class="pingjia-111" style="flex: 1">
              <div class="pingjia-1111">
                <img :src="item.user.avatar" />
              </div>
              <div class="pingjia-1112">
                <div class="pingjia-11121">{{ item?.user?.name || "" }}</div>
                <div class="pingjia-11122">
                  <van-rate
                    :readonly="true"
                    v-model="item.goods_star"
                    :size="16"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                  />
                  <!-- <img src="../static/images/xing1.png" />
                  <img src="../static/images/xing1.png"/>
                  <img src="../static/images/xing1.png" />
                  <img src="../static/images/xing1.png"/>
                  <img src="../static/images/xing2.png" />-->
                  {{ item.goods_star }}分
                </div>
              </div>
            </div>
            <div class="pingjia-112" style="flex: 1; text-align: right">{{ item.created_at }}</div>
          </div>
          <div class="pingjia-12">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div class="nav-botjg"></div>
    <div class="nav-botbox">
      <div class="nav-bot1 nav-bot1on" @click="home">
        <div class="nav-bot11">
          <img src="../static/images/bot1.png" />
          <img src="../static/images/bot11.png" />
        </div>
        <div class="nav-bot12">首页</div>
      </div>
      <div class="nav-bot1" @click="orderlist">
        <div class="nav-bot11">
          <img src="../static/images/bot2.png" />
          <img src="../static/images/bot22.png" />
        </div>
        <div class="nav-bot12">我的订单</div>
      </div>
      <div class="nav-bot1" @click="my">
        <div class="nav-bot11">
          <img src="../static/images/bot3.png" />
          <img src="../static/images/bot33.png" />
        </div>
        <div class="nav-bot12">我的</div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeApi } from "@/Api/homePage.api";
import { handelVerify } from "@/Api/signIn.api";
import { Notify } from "vant";
export default {
  name: "",
  data() {
    return {
      imagesList: [],
      noticeList: [],
      reservationCategory: [
        {
          name: "",
          desc: ""
        }
      ],
      serveGoodsList: [],
      evaluation: [],
      value: 4,
      valueData: "",
      title: "太康医院",
      address: "",
      longitude: "", //经度
      latitude: "", //纬度
      addressList: [],
      key: "GAVBZ-BKU37-FWDXP-PAQ7T-BH4L7-XCBSN" // 腾讯地图 key
    };
  },
  methods: {
    pageTo() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/about");
      });
    },
    service(id) {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push({ path: "/service", query: { id } });
      });
    },
    appraiseList() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push({ path: "/appraise-list" });
      });
    },

    mall() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/mall");
      });
    },

    home() {
      this.$router.push("/");
    },

    orderlist() {
      this.$router.push("/order-list");
    },

    my() {
      this.$router.push("/my");
    },

    questionnaire() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/questionnaire");
      });
    },

    vip() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/vip");
      });
    },

    servicedetail(id) {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push({
          path: "/mall-detail",
          query: {
            id: id
          }
        });
      });
    },
    sousuoFun() {
      // console.log(data);
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        if (this.valueData) {
          this.$router.push({
            path: "/service-search",
            query: {
              data: this.valueData
            }
          });
        } else {
          return Notify({ type: "warning", message: "请您填写搜索内容" });
        }
      });
    },

    news() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/news");
      });
    },

    note() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        this.$router.push("/note");
      });
    },
    navigationFun() {
      handelVerify().then(({ data: RuleData }) => {
        if (!RuleData)
          return Notify({ type: "danger", message: "请您先完善个人信息！" });
        console.log(123123);
        window.location.href = `https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${this.latitude},${this.longitude};title:${this.title};addr:${this.address}
              6020号&key=${this.key}&referer=zx$init_view=1`;
      });
    },
    bannerClick(data) {
      // console.log(data);
      if (!data.url) return;
      window.open(data.url);
    }
  },
  created() {
    const token = this.$route.query.token;
    homeApi()
      .then(res => {
        this.imagesList = res.data.imagesList;
        this.noticeList = res.data.noticeList;
        this.reservationCategory = res.data.reservationCategory;
        // this.serveGoodsList = res.data.serveGoodsList;
        this.serveGoodsList = res.data.goodsList;
        if (res.data.reservationReview.length >= 2) {
          this.evaluation.push(res.data.reservationReview[0]);
          this.evaluation.push(res.data.reservationReview[1]);
        } else {
          this.evaluation = res.data.reservationReview;
        }
        this.addressList = res.data.reservationReview;
        console.log(this.evaluation);
        this.longitude = res.data.map.longitude;
        this.latitude = res.data.map.latitude;
      })
      .catch(err => {});
  }
  // beforeRouteEnter(to,from, next){
  // console.log(from.name);
  // if(to.name == 'home'){
  //    const token = localStorage.getItem('TOKEN')
  //    if(token){
  //     next()
  //    }else{
  //     window.location.href = '/api/official/login'
  //    }
  // }
  // }
};
</script>

<style scoped lang="scss">
.index-topbox {
  width: 100%;
  height: 215px;
  overflow: hidden;
  background: linear-gradient(to right, #32b1a8 0%, #36e9e2 100%);
}

.logo {
  width: 159px;
  height: 33px;
  overflow: hidden;
  margin: 10px auto 0;
}

.logo img {
  width: 100%;
  height: 100%;
}

.search-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  margin: 12px auto 0;
}

.search-1 {
  width: calc(100% - 90px);
  height: 35px;
  overflow: hidden;
  float: left;
  margin-top: 4px;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 18px;
}

.search-11 {
  width: calc(100% - 70px);
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  float: left;
  background: url(../static/images/search.png) left 15px center no-repeat;
  background-size: 15px 15px;
}

.search-11 input {
  display: block;
  width: calc(100% - 35px);
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  float: left;
  padding-left: 35px;
}

.search-11 input::-webkit-input-placeholder {
  color: #fff;
}

.search-12 {
  width: 49px;
  height: 27px;
  overflow: hidden;
  float: right;
  margin-right: 6px;
  margin-top: 4px;
  background: #ffffff;
  border-radius: 14px;
}

.search-12 button {
  display: block;
  width: 100%;
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-size: 14px;
  color: #00988d;
}

.search-2 {
  width: 60px;
  height: 41px;
  overflow: hidden;
  float: right;
}

.search-2 img {
  width: 100%;
  height: 100%;
}

.banner-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  padding: 10px 10px 0;
  background: #fff;
  border-radius: 9px;
  position: relative;
  margin-top: -110px;
  z-index: 9;
}

.banner {
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.van-swipe {
  width: 100%;
  height: 150px;
}

.banner img {
  width: 100%;
  height: 100%;
}

.van-swipe__indicator {
  background: #b6b6b6;
}

.van-swipe__indicator--active {
  background: #ffb831;
  width: 10px;
}

.notify-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  background: url(../static/images/notify-bg.png) center top no-repeat;
  background-size: 100% 100px;
  border-radius: 9px 9px 0 0;
  margin: 15px auto 0;
}

.notify-1 {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  margin: 12px auto 0;
}

.notify-11 {
  width: 21px;
  height: 27px;
  overflow: hidden;
  float: left;
}

.notify-11 img {
  width: 100%;
  height: 100%;
}

.notify-12 {
  width: calc(100% - 45px);
  height: 25px;
  line-height: 25px;
  font-size: 15px;
  color: #000000;
  float: right;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-notice-bar {
  width: 100%;
  height: 25px;
  overflow: hidden;
  background: none;
  padding: 0;
  color: #000;
  font-size: 15px;
  line-height: 25px;
}

.van-notice-bar i {
  display: none;
}

.van-icon:before {
  display: none;
}

.van-icon-volume-o:before {
  content: "";
}

.van-notice-bar__wrap {
  width: 100%;
  overflow: hidden;
}

.notice-swipe {
  height: 25px;
  line-height: 25px;
}

.nav-box {
  width: calc(100% - 16px);
  height: auto;
  overflow: hidden;
  margin: 10px auto;
  background: #fff;
  box-sizing: border-box;
  // border: 1px solid;
  // border-image: linear-gradient(to bottom, #fff, #b6dddf) 1;

  background: url(../static/images/icon-bj.png) center bottom no-repeat;
  background-size: 100% 101px;
  border-radius: 8px;
}

.nav-1 {
  width: 25%;
  height: auto;
  overflow: hidden;
  float: left;
  padding-bottom: 10px;
}

.nav-11 {
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 18px auto 0;
}

.nav-11 img {
  width: 100%;
  height: 100%;
}

.nav-12 {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  color: #000;
}

.biaoti-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  margin: 0px auto 0;
}

.biaoti-boxaaa {
  margin: 0 auto 0;
}

.biaoti-1 {
  display: inline-block;
  float: left;
  height: auto;
  line-height: 38px;
  font-size: 17px;
  color: #333333;
  overflow: hidden;
}

.biaoti-2 {
  display: flex;
  width: 60px;
  height: 38px;
  overflow: hidden;
  float: right;
  font-size: 13px;
  color: #999999;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
}

.biaoti-2 img {
  width: 6px;
  height: 10px;
  margin-left: 4px;
}

.leixing-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.leixing-1 {
  width: calc(50% - 5px);
  height: 175px;
  overflow: hidden;
  float: left;
  background: url(../static/images/leixing-1.png) center top no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
}

.leixing-11 {
  width: calc(100% - 20px);
  height: 23px;
  line-height: 23px;
  font-size: 18px;
  color: #22696c;
  overflow: hidden;
  margin-top: 15px;
}

.leixing-12 {
  width: calc(100% - 20px);
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  color: #639496;
  overflow: hidden;
}

.leixing-13 {
  width: 63px;
  height: 21px;
  line-height: 21px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 11px;
  text-align: center;
  font-size: 10px;
  background: #44a4a9;
}

.leixing-box .leixing-1:nth-child(n + 2) {
  float: right;
  height: 83px;
}

.leixing-box .leixing-1:nth-child(2) {
  background: url(../static/images/leixing-2.png) center top no-repeat;
  background-size: 100% 100%;
  margin-bottom: 9px;
}

.leixing-box .leixing-1:nth-child(3) {
  background: url(../static/images/leixing-3.png) center top no-repeat;
  background-size: 100% 100%;
}

.xuzhi-box {
  width: calc(100% - 20px);
  padding-bottom: 9px;
  height: auto;
  margin: 15px auto 0;
  overflow: hidden;
  background: url(../static/images/xuzhi-bj.png) center top no-repeat;
  background-size: 100% 110px;
}

.xuzhi-1 {
  width: 120px;
  height: auto;
  overflow: hidden;
  margin-left: 32px;
  margin-top: 18px;
  line-height: 27px;
  font-size: 16px;
  color: #009a90;
  overflow: hidden;
}

.xuzhi-2 {
  width: 120px;
  height: auto;
  overflow: hidden;
  margin-left: 32px;
  margin-top: 4px;
  line-height: 15px;
  font-size: 11px;
  color: rgba(0, 154, 144, 0.74);
  overflow: hidden;
}

.xuzhi-3 {
  width: 145px;
  height: 30px;
  margin-top: 9px;
  overflow: hidden;
  border-radius: 15px;
  margin-left: 24px;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: linear-gradient(to right, #17beb1 0%, #34b5fa 100%);
  box-shadow: -1px -1px 5px 5px rgba(0, 154, 144, 0.1);
}

.xuzhi-3 img {
  width: 15px;
  height: 19px;
  margin-left: 6px;
}

.fuwu-box {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  padding-bottom: 5px;
}

.fuwu-1 {
  width: calc(50% - 5px);

  height: auto;
  // overflow: hidden;
  float: left;
  margin-bottom: 10px;
}

.fuwu-box .fuwu-1:nth-child(2n) {
  float: right;
}

.fuwu-11 {
  width: 100%;
  height: 173px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #eee;
  box-sizing: border-box;
}

.fuwu-11 img {
  width: 100%;
  height: 100%;
}

.fuwu-12 {
  width: 100%;
  height: 27px;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 7px;
  font-size: 16px;
  color: #333333;
}

.fuwu-12 {
  width: 100%;
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #f54745;
}

.fuwu-12 font {
  font-size: 15px;
}

.pingjia-max {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  background: url(../static/images/pingjia-bj.png) center top no-repeat;
  background-size: 100% 100px;
  border-radius: 9px 9px 0 0;
  min-height: 100px;
  margin-bottom: 15px;
}

.pingjia-bt {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  margin: 6px auto 0;
  line-height: 38px;
  font-size: 17px;
  color: #000000;
}

.pingjia-box {
  width: calc(100% - 16px);
  height: auto;
  overflow: hidden;
  margin: 0px auto;
  padding-bottom: 3px;
  background: #fff;
  box-sizing: border-box;
  // border: 1px solid;
  // border-image: linear-gradient(to bottom, #fff, #c3e8e5) 1;
  background: url(../static/images/pingjia-bja.png) center bottom no-repeat #fff;
  background-size: 100% 210px;
  border-radius: 8px;
}

.pingjia-1 {
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  padding: 15px 0;
  border-bottom: 1px solid #e8e8e8;
}

.pingjia-box .pingjia-1:last-child {
  border-bottom: none;
}

.pingjia-11 {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.pingjia-111 {
  width: calc(100% - 130px);
  height: auto;
  overflow: hidden;
  float: left;
  margin-left: 2px;
}

.pingjia-1111 {
  width: 28px;
  height: 28px;
  overflow: hidden;
  float: left;
  border-radius: 50%;
  margin-top: 8px;
}

.pingjia-1111 img {
  width: 100%;
  height: 100%;
}

.pingjia-1112 {
  width: calc(100% - 38px);
  height: auto;
  overflow: hidden;
  float: right;
}

.pingjia-11121 {
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 15px;
  color: #333333;
  overflow: hidden;
}

.pingjia-11122 {
  width: 100%;
  height: 22px;
  font-size: 15px;
  color: #ffa200;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.pingjia-11122 img {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

.pingjia-112 {
  width: 105px;
  height: auto;
  overflow: hidden;
  line-height: 21px;
  margin-top: 8px;
  float: right;
  font-size: 12px;
  color: #999999;
}

.pingjia-12 {
  width: 100%;
  height: auto;
  line-height: 25px;
  font-size: 16px;
  color: #333333;
  overflow: hidden;
  margin-top: 3px;
}

::v-deep .van-rate {
  margin: 0;
}

::v-deep .van-notice-bar i {
  display: none;
}

.underline {
  text-decoration: underline;
}
</style>
