import Cookies from 'js-cookie'

const TokenyKey = 'Token'

//获取token
export function getToke () {
  return Cookies.get(TokenyKey)
}
//设置token
export function setToken (token) {
  return Cookies.set(TokenyKey, token)
}
//获取去参数链接
// export function GetQueryString(name) {
//     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
//     // var r = location.href.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配

//     var context = "";
//     if (r != null)
//         context = decodeURIComponent(r[2]);
//     reg = null;
//     r = null;
//     return context == null || context == "" || context == "undefined" ? "" : context;
// }
export function queryURLParams (URL) {
  // const url = location.search; // 项目中可直接通过search方法获取url中"?"符后的字串
  let url = URL.split("?")[1];
  if (url) {
    let obj = {}; // 声明参数对象
    let arr = url.split("&"); // 以&符号分割为数组
    for (let i = 0; i < arr.length; i++) {
      let arrNew = arr[i].split("="); // 以"="分割为数组
      obj[arrNew[0]] = arrNew[1];
    }
    return obj;
  } else {
    return {}
  }
};
//获取当前时间的下一天
export function getNextDay (d) {
  d = new Date(d);
  d = +d + 1000 * 60 * 60 * 24;
  d = new Date(d);

  var yy = d.getFullYear();
  var mm = d.getMonth() + 1;
  var dd = d.getDate();
  var nextday = yy + "-";
  if (mm < 10) { nextday += "0" }
  nextday += mm + "-";
  if (dd < 10) { nextday += "0" }
  nextday += dd;
  return nextday;

};
//时间戳转换

export function filterTime (time) {
  const date = new Date(time)
  const Y = date.getFullYear()
  const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const D = date.getDate()
  let H = date.getHours() // 小时
  let S = date.getMinutes() // 分钟
  if (JSON.stringify(H).length == 1) {
    H = "0" + JSON.stringify(H)
  }
  if (JSON.stringify(S).length == 1) {
    S = "0" + JSON.stringify(S)
  }
  console.log(H, S);

  return `${Y}-${M}-${D} ${H}:${S}`
};
//时间差计算
export const parseTimeUsedNew = (inputTime, type) => {
  let obj = {
    text: '-',
    day: 0,
    isDanger: false
  }

  if (!inputTime || arguments.length === 0) {
    return obj;
  }
  let time
  let curTime = new Date().getTime();
  if (type == 0) {
    time = curTime / 1000 - inputTime / 1000;
  } else {
    time = inputTime / 1000 - curTime / 1000;
    console.log(time, inputTime, curTime);
  }
  if (time < 60) {
    let s = parseInt(time)
    obj.text = `${s}秒`
  } else if (time < 3600) {
    let m = parseInt(time / 60)
    let s = parseInt(time % 60)
    obj.text = `${m}分${s}秒`
    obj.date = m * 60 + s
  } else if (time < 86400) {
    let h = parseInt(time / 3600)
    let min = time - h * 3600
    let m = parseInt(min / 60)
    obj.text = `${h}小时${m}分`
  } else if (time > 86400) {
    let day = parseInt(time / 86400)
    let hour = time - day * 86400
    let h = parseInt(hour / 3600)
    let min = hour - h * 3600
    let m = parseInt(min / 60)
    if (day > 7 || (day == 7 && h > 0)) {
      obj.isDanger = false;
    }

    obj.day = day;
    obj.text = `${day}天${h}小时`
  }
  return obj
};

export function debounce (func, delay = 500) {
  let timeoutId = null;
  return function () {
    const context = this;
    const args = arguments;
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null
    }
    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}
